import Vue from 'vue'
import Vuex from 'vuex'
import login from './login'
import contrato from './contrato'
import tipoproducto from './tipoproducto'
import tipocontrato from './tipocontrato'
import producto from './producto'
import cliente from './cliente'
import responsable from './responsable'
import nav from './nav'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    login,
    contrato,
    tipoproducto,
    tipocontrato,
    producto,
    cliente,
    responsable,
    nav
  }
})
