<template>
    <div>
        <Nuevo :dialog="dialog" @dialog="dialog = $event" :Item="itemSelected"/>
        <v-card>
            <v-card-title>
            {{ Titulo }}
            <v-spacer></v-spacer>
            <v-btn
                v-if="Excel"
                color = "primary"
                dark
                small
                class="mb-2"
                @click="DescargarExcel()"
            >
            Descargar Tabla
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Busqueda"
                single-line
                hide-details
            ></v-text-field>            
            <v-spacer></v-spacer>
            <v-btn
                v-if="Crear"
                color="primary"
                dark
                class="mb-2"
                @click="nuevoItem()"
                >
                Nuevo
            </v-btn>
            </v-card-title>
            <v-card-text>
            <v-data-table
            dense
            :headers="Cabecera"
            :items="Items"
            :items-per-page="5"
            :search="search"
            class="elevation-1"
            >   <template v-slot:[`item.NombreCompleto`]="{ item }">
                    {{item.RES_Apellidos}} {{item.RES_Nombres}}   
                </template>         
                <template v-slot:[`item.Accion`]="{ item }">                    
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                height="22"
                                width="22"
                                color="blue lighten-2"
                                fab
                                x-small
                                dark
                                @click="editarItem(item)"
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon x-small>mdi-pencil</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Editar</span> 
                    </v-tooltip>

                    
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                style="margin-left:5px"
                                height="22"
                                width="22"
                                color="red lighten-2"
                                fab
                                x-small
                                dark
                                @click="eliminar(item)"
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon x-small>mdi-delete</v-icon>
                            </v-btn>  
                        </template>
                        <span>Eliminar</span> 
                    </v-tooltip>
                </template>
            </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Nuevo from './nuevo.vue'
import exportFromJSON from "export-from-json"
export default {
    props: ["Titulo", "Cabecera", "Items", "Excel", "Crear"],
    component: {
        Nuevo
    },
    data() {
        return {
            search: "",
            dialog: false,
            itemSelected: { }
        };
    },
    methods: {
        ...mapActions("responsable", ["eliminarResponsable"]),
        nuevoItem() {
            this.itemSelected = {
                id : 0,
                RES_NumeroDocumento : '',
                RES_Nombres : '',
                RES_Apellidos : '',
                RES_Direccion : '',
                RES_Email : '',
                RES_Telefono : '',
            }
            this.dialog = true;
        },
        editarItem(item) {
            this.itemSelected = item
            this.dialog = true;
        },
        eliminar(item) {
            this.$alertify.confirm(
                "Desea Eliminar este Item ID: " + item.id + "?", 
                () => {
                        this.eliminarResponsable(item)
                        .then(r => {
                            this.$alertify.success("Item ID: " + item.id + "  Eliminado correctamente");
                        });
                    }, 
                () => this.$alertify.error("Se cancelo la acción")
            ).setHeader('<em> Aviso </em> ');
        },
        DescargarExcel(){
            const data = this.Items;
            const nombreArchivo = 'download';
            const exportType = exportFromJSON.types.xls; //xls, json, css, html,txt 
            exportFromJSON({data, nombreArchivo, exportType})
        }
    },
    components: { Nuevo }
};
</script>
