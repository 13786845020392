import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

var token = localStorage.getItem('token')
export default{
  namespaced:true,
  state: {
    Items : [],
    ItemsResponsable : [],
    ItemsAllResponsable : [],
    ItemsCliente : [],
    ItemsAllCliente : [],
    ItemsProducto : [],
    ItemsAllProducto : [],
    ItemsTipoProducto : [],
    ItemsTipoContrato : [],
    ItemsDetalleTabla : [],
    ItemsPdfContrato : [],
    ItemsPdfDetalle : [],
    ItemsPdfMedidas : []
  },
  getters: {
    Items(state){
      return state.Items
    },
    ItemsResponsable(state){
      return state.ItemsResponsable
    },
    ItemsAllResponsable(state){
      return state.ItemsAllResponsable
    },  
    ItemsCliente(state){
      return state.ItemsCliente
    },  
    ItemsAllCliente(state){
      return state.ItemsAllCliente
    },    
    ItemsProducto(state){
      return state.ItemsProducto
    },
    ItemsAllProducto(state){
      return state.ItemsAllProducto
    },
    ItemsTipoProducto(state){
      return state.ItemsTipoProducto
    },
    ItemsTipoContrato(state){
      return state.ItemsTipoContrato
    },    
    ItemsDetalleTabla(state){
      return state.ItemsDetalleTabla
    },    
    ItemsPdfContrato(state){
      return state.ItemsPdfContrato
    },    
    ItemsPdfDetalle(state){
      return state.ItemsPdfDetalle
    },    
    ItemsPdfMedidas(state){
      return state.ItemsPdfMedidas
    }
        
  },
  mutations: {
    LlenarItems(state,data){
      state.Items = data
    },
    LlenarItemsResponsable(state,data){
      state.ItemsResponsable = data
    },
    LlenarItemsAllResponsable(state,data){
      state.ItemsAllResponsable = data
    }, 
    LlenarItemsCliente(state,data){
      state.ItemsCliente = data
    },  
    LlenarItemsAllCliente(state,data){
      state.ItemsAllCliente = data
    },   
    LlenarItemsProducto(state,data){
      state.ItemsProducto = data
    },
    LlenarItemsAllProducto(state,data){
      state.ItemsAllProducto = data
    },
    LlenarItemsTipoProducto(state,data){
      state.ItemsTipoProducto = data
    },
    LlenarItemsTipoContrato(state,data){
      state.ItemsTipoContrato = data
    },    
    LlenarItemsDetalleTabla1(state,data){
      state.ItemsDetalleTabla[data.pos] = data.datos
    },
    LlenarItemsDetalleTabla(state,data){
      state.ItemsDetalleTabla = data
    },
    LlenarItemsPdfContrato(state,data){
      state.ItemsPdfContrato = data
    },
    LlenarItemsPdfDetalle(state,data){
      state.ItemsPdfDetalle = data
    },
    LlenarItemsPdfMedidas(state,data){
      state.ItemsPdfMedidas = data
    }
    
  },
  actions: {
    cargarContrato:async function({commit}){
      try {
        const  url = "contrato"
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        await axios.get(url,config)
        .then(response => {
          commit('LlenarItems',response.data.success.resultado);       
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
        }); 
      } catch (error) {
        console.log(error)
      }
    },
    cargarResponsable:async function({commit}){
      try {
        const  url = "responsable"
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        await axios.get(url,config)
        .then(response => {
          var arrayResponsable = []
          response.data.success.resultado.forEach((element,i )=> {
            arrayResponsable[i]={value:element.id, text:element.RES_Apellidos+' '+element.RES_Nombres}
          });
          commit('LlenarItemsAllResponsable',response.data.success.resultado);       
          commit('LlenarItemsResponsable',arrayResponsable);       
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
        }); 
      } catch (error) {
        console.log(error)
      }
    },
    cargarProducto:async function({commit}){
      try {
        const  url = "producto"
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        await axios.get(url,config)
        .then(response => {
          var arrayproducto = []
          response.data.success.resultado.producto.forEach((element,i) => {
            arrayproducto[i] = {value:element.id, text:element.PRO_Nombre+' '+element.PRO_Color}
          });
          var arraytipo = []
          response.data.success.resultado.tipo_producto.forEach((element,i )=> {
            arraytipo[i]={value:element.id, text:element.TIP_Nombre}
          });
          var arraytipocontrato = []
          response.data.success.resultado.tipo_contrato.forEach((element,i )=> {
            arraytipocontrato[i]={value:element.id, text:element.TIC_Nombre}
          });
          
          commit('LlenarItemsAllProducto',response.data.success.resultado.producto);     
          commit('LlenarItemsProducto',arrayproducto);     
          commit('LlenarItemsTipoProducto',arraytipo); 
          commit('LlenarItemsTipoContrato',arraytipocontrato); 
              
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
        }); 
      } catch (error) {
        console.log(error)
      }
    },
    cargarCliente:async function({commit}){
      try {
        const  url = "cliente"
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        await axios.get(url,config)
        .then(response => {
          var arraycliente = []
          response.data.success.resultado.forEach((element,i) => {
            arraycliente[i] = {value:element.id, text:element.CLI_Nombre}
          });
          commit('LlenarItemsCliente',arraycliente); 
          commit('LlenarItemsAllCliente',response.data.success.resultado); 
                
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
        }); 
      } catch (error) {
        console.log(error)
      }
    },    
    cargarDetalleTabla:async function({commit,state},datos){
      var pos = state.ItemsDetalleTabla.length
      var array=[]
      state.ItemsDetalleTabla.forEach((element, i)=>{
        array[i] = element
      })
      array[pos]=datos
      commit('LlenarItemsDetalleTabla',array);
    },
    agregarContrato: async function({commit,dispatch},datos){
      var  url = "contrato/store"
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      await axios.post(url,datos,config)
      .then(response => {
        console.log(response)
        //dispatch('cargarCliente')     
      })
      .catch(error => {
        this.errorMessage = error.response.data;
        console.error("error!", error);
      }); 
    },
    detalleContrato:async function({commit},$id){
      try {
        var datos = {"id":$id}
        const  url = "contrato/show"
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        await axios.post(url,datos,config)
        .then(response => {
          console.log(response.data.success.resultado)
          var Contrato = [] ;
          response.data.success.resultado.contrato.forEach(element =>{
            var fecha = element.CON_Fecha.split('-') 
            console.log(fecha)
            element.año = fecha[0];
            element.mes = fecha[1];
            element.dia = fecha[2];
            Contrato = element
          });
          
          commit('LlenarItemsPdfContrato',Contrato);       
          commit('LlenarItemsPdfDetalle',response.data.success.resultado.detallecontrato);       
          commit('LlenarItemsPdfMedidas',response.data.success.resultado.detallecontratomedida);       
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
        }); 
      } catch (error) {
        console.log(error)
      }
    },
    editContrato:async function({commit},$id){
      try {
        var datos = {"id":$id}
        const  url = "contrato/edit"
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        await axios.post(url,datos,config)
        .then(response => {
          console.log(response.data.success.resultado)
          var Contrato = [] ;
          response.data.success.resultado.contrato.forEach(element =>{
            var fecha = element.CON_Fecha.split('-') 
            console.log(fecha)
            element.año = fecha[0];
            element.mes = fecha[1];
            element.dia = fecha[2];
            Contrato = element
          });
          
          commit('LlenarItemsPdfContrato',Contrato);       
          commit('LlenarItemsDetalleTabla',response.data.success.resultado.detallecontrato);       
          commit('LlenarItemsPdfMedidas',response.data.success.resultado.detallecontratomedida);       
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
        }); 
      } catch (error) {
        console.log(error)
      }
    },
    editarDetalle:async function({commit,state},datos){
      try {
        var array=[]
        state.ItemsDetalleTabla.forEach((element, i)=>{
          if(element.PRO_Id == datos.PRO_Id ){
            element.CON_Precio = datos.CON_Precio 
          }
          array[i] = element
        })
        commit('LlenarItemsDetalleTabla',array); 
      } catch (error) {
        console.log(error)
      }
    },
    eliminarDetalle:async function({commit,state},datos){
      try {
        var array=[]
        var con = 0;
        state.ItemsDetalleTabla.forEach((element)=>{
          if(element.PRO_Id != datos.PRO_Id ){            
            array[con] = element
            con++
          }
        })
        commit('LlenarItemsDetalleTabla',array); 
      } catch (error) {
        console.log(error)
      }
    },
    nuevoContrato:async function({commit}){
      commit('LlenarItemsPdfContrato',[]);       
      commit('LlenarItemsDetalleTabla',[]);       
      commit('LlenarItemsPdfMedidas',[]);  
    },
    
    
    
  }

}


