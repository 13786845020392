import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

var token = localStorage.getItem('token')
export default{
  namespaced:true,
  state: {
    Items : [],
  },
  getters: {
    Items(state){
      return state.Items
    }
  },
  mutations: {
    LlenarItems(state,data){
      state.Items = data
    }
  },
  actions: {
    cargarTipoProducto:async function({commit}){
      try {
        const  url = "tipoproducto"
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        await axios.get(url,config)
        .then(response => {
          commit('LlenarItems',response.data.success.resultado);       
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
        }); 
      } catch (error) {
        console.log(error)
      }
    },    
    agregarTipoProducto: async function({commit,dispatch},datos){
      var  url = "tipoproducto/store"
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      await axios.post(url,datos,config)
      .then(response => {
        dispatch('cargarTipoProducto')     
      })
      .catch(error => {
        this.errorMessage = error.response.data;
        console.error("error!", error);
      }); 
    },
    eliminarTipoProducto: async function({commit,dispatch},datos){

      const  url = `tipoproducto/${datos.id}`
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      await axios.delete(url,config)
      .then(response => { 
        dispatch('cargarTipoProducto')     
      })
      .catch(error => {
        this.errorMessage = error.response.data;
        console.error("error!", error);
      }); 
    },     
  }

}


