<template>
  <div style="margin-top:30px">
    <tabla  Titulo="Cliente" :Cabecera="Cabecera" :Items="Items" :Excel="false" :Crear="true" />
  </div>
</template>
<script>
import tabla from "@/components/cliente/tabla.vue"
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Cliente",
  components : {
    tabla
  },
  created(){
    const data = {menu1:-1,menu2:2,submenu2:3,menu3:0,submenu3:0};
    this.CargarMenu(data);
    this.cargarCliente();
  },
  data(){
    return{
      Cabecera:[
        {text:'Id',value:'id'},
        {text:'DNI',value:'CLI_DNI'},
        {text:'Nombre',value:'CLI_Nombre'},
        {text:'Accion',value:'Accion'}
      ]
    }
  },
  methods:{
    ...mapActions('cliente',['cargarCliente']),
    ...mapActions('nav',['CargarMenu'])
  },

  computed:{
    ...mapGetters('cliente',['Items'])    
  }


};
</script>




