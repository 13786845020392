<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
          <v-alert
          text
          prominent
          :type="color"
          :icon="icono"
        >
          {{titulo}}
        </v-alert>
        
        <v-card-text style="padding:0 15px 2px">{{mensaje}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="cerrarDialogo"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  export default {
    name: "dialogalert",
    props:['dialog','color','icono','titulo','mensaje'],
    data () {
      return {
        
      }
    },
    methods:{
      cerrarDialogo(){
        this.$emit('dialog',false);
      }
    }
    


  }
</script>