var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Nuevo',{attrs:{"dialog":_vm.dialog,"Item":_vm.itemSelected},on:{"dialog":function($event){_vm.dialog = $event}}}),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.Titulo)+" "),_c('v-spacer'),(_vm.Excel)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","small":""},on:{"click":function($event){return _vm.DescargarExcel()}}},[_vm._v(" Descargar Tabla ")]):_vm._e(),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Busqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),(_vm.Crear)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.nuevoItem()}}},[_vm._v(" Nuevo ")]):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.Cabecera,"items":_vm.Items,"items-per-page":5,"search":_vm.search},scopedSlots:_vm._u([{key:"item.Accion",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"height":"22","width":"22","color":"blue lighten-2","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.editarItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"5px"},attrs:{"height":"22","width":"22","color":"red lighten-2","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.eliminar(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }