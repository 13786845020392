<template>
  <div style="margin-top:30px">
    <tabla  Titulo="Tipo de Contrato" :Cabecera="Cabecera" :Items="Items" :Excel="false" :Crear="true" />
  </div>
</template>
<script>
import tabla from "@/components/tipocontrato/tabla.vue"
import { mapGetters, mapActions } from "vuex";
export default {
  name: "TipoContrato",
  components : {
    tabla
  },
  created(){
    const data = {menu1:-1,menu2:2,submenu2:1,menu3:0,submenu3:0};
    this.CargarMenu(data);
    this.cargarTipoContrato();
  },
  data(){
    return{
      Cabecera:[
        {text:'Id',value:'id'},
        {text:'Nombre',value:'TIC_Nombre'},
        {text:'Accion',value:'Accion'}
      ]
    }
  },
  methods:{
    ...mapActions('tipocontrato',['cargarTipoContrato']),
    ...mapActions('nav',['CargarMenu'])
  },

  computed:{
    ...mapGetters('tipocontrato',['Items'])    
  }


};
</script>




