<template>
  <div>
    <!--  -->
    <body style="padding:20px" >
      <v-row>
        <v-col
          cols="12"
          md="3"
          sm="12"
        >
            <img src="/dama.jpeg"          
            height="180"
            width="125" >
        </v-col>
        <v-col
            cols="12"
            md="6"
            sm="12"
            align="center"
        >
          <div align="center" >
            <img src="/logo.png"          
            height="80"
            width="180" >                    
          </div>
          <b><span style="font-size:12px" >DE: ZAÑARTU GONZALES KARLA SILVANA</span></b><br>
          <span style="font-size:12px" >Exclusividad en vestidos de novia, Fiestas, 15 años,<br> Promociones y alquiler de vestidos</span><br>
          <b><span style="font-size:10px;" >LADISLAO ESPINAR N° 67 - CEL 977298203 - PACASMAYO - LA LIBERTAD</span></b><br>
        </v-col>
        <v-col
          cols="12"
          md="3"
          sm="12"
        >
          <v-card
            color="#FFF"
            border="1"
          >
            <v-toolbar 
              align="center"
              height="40"
              color="#888">
              <span style="color:white;font-size:14px" >CONTRATO</span>
            </v-toolbar>
            <v-card-subtitle align="center" >          
              <span style="align:center;font-size:14px" >R.U.C 10434718169 </span> <br>
              <span style="color:red;font-size:14px" align="center" >0000815</span><br>
            </v-card-subtitle>                    
          </v-card>

          <v-card class="pa-0 ma-0">                 
            <v-chip-group
              active-class="deep-purple--text text--accent-4"
              style="padding-left:8px"
            >
              <v-chip>20</v-chip>
              <v-chip>06</v-chip>
              <v-chip>2022</v-chip>
            </v-chip-group>
          </v-card>
        </v-col>
      </v-row>
      <v-row>        
        <v-col
          cols="12"
          md="12"
          sm="12"
        >
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="10%" class="text-left">
                    CANT.
                  </th>
                  <th width="80%" class="text-left">
                    DESCRIPCION
                  </th>
                  <th width="10%" class="text-left">
                    Importe
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in desserts"
                  :key="item.name"
                >
                  <td class="text-left" >{{ item.calories }}</td>
                  <td class="text-left" >{{ item.name }}</td>
                  <td class="text-left" >{{ item.calories }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col
          cols="12"
          md="5"
          sm="12"
        >
        </v-col>
        <v-col
          cols="12"
          md="7"
          sm="12"
        >
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="40%" class="text-left">
                    GARANTIA: S/. 
                  </th>
                  <th width="30%" class="text-left">
                    
                  </th>
                  <th width="30%" class="text-left">
                    XXXXXXXXXXXX
                  </th>
                </tr>
                <tr>
                  <th width="40%" class="text-left">
                    D.N.I:
                  </th>
                  <th width="30%" class="text-left">
                    
                  </th>
                  <th width="30%" class="text-left">
                    XXXXXXXXXXXX
                  </th>
                </tr>
                <tr>
                  <th width="40%" class="text-left">
                    1ra. Prueba 
                  </th>
                  <th width="30%" class="text-left">
                    20 / 06 /2022
                  </th>
                  <th width="30%" class="text-left">
                    XXXXXXXXXXXX
                  </th>
                </tr>
                <tr>
                  <th width="40%" class="text-left">
                    2da. Prueba 
                  </th>
                  <th width="30%" class="text-left">
                    20 / 06 /2022
                  </th>
                  <th width="30%" class="text-left">
                    XXXXXXXXXXXX
                  </th>
                </tr>
                <tr>
                  <th width="40%" class="text-left">
                    3ra. Prueba 
                  </th>
                  <th width="30%" class="text-left">
                    20 / 06 /2022
                  </th>
                  <th width="30%" class="text-left">
                    XXXXXXXXXXXX
                  </th>
                </tr>
              </thead>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>       
    </body>
    
    <v-btn
      class="mx-2"
      fab
      dark
      color="teal"
      @click="generateReport"
    >
      <v-icon dark>
        mdi-format-list-bulleted-square
      </v-icon>
    </v-btn>
    <VueHtml2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="myPDF"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        
        pdf-content-width="800px"
        ref="html2Pdf"
    >
      <section slot="pdf-content"> 
        <section class="pdf-item">
          <section class="pdf-item" style="padding:20px">
            <v-row>
        <v-col
          cols="12"
          md="3"
          sm="12"
        >
            <img src="/dama.jpeg"          
            height="180"
            width="125" >
        </v-col>
        <v-col
            cols="12"
            md="6"
            sm="12"
            align="center"
        >
          <div align="center" >
            <img src="/logo.png"          
            height="80"
            width="180" >                    
          </div>
          <b><span style="font-size:12px" >DE: ZAÑARTU GONZALES KARLA SILVANA</span></b><br>
          <span style="font-size:12px" >Exclusividad en vestidos de novia, Fiestas, 15 años,<br> Promociones y alquiler de vestidos</span><br>
          <b><span style="font-size:10px;" >LADISLAO ESPINAR N° 67 - CEL 977298203 - PACASMAYO - LA LIBERTAD</span></b><br>
        </v-col>
        <v-col
          cols="12"
          md="3"
          sm="12"
        >
          <v-card
            color="#FFF"
            border="1"
          >
            <v-toolbar 
              align="center"
              height="40"
              color="#888">
              <span style="color:white;font-size:14px" >CONTRATO</span>
            </v-toolbar>
            <v-card-subtitle align="center" >          
              <span style="align:center;font-size:14px" >R.U.C 10434718169 </span> <br>
              <span style="color:red;font-size:14px" align="center" >0000815</span><br>
            </v-card-subtitle>                    
          </v-card>

          <v-card class="pa-0 ma-0">                 
            <v-chip-group
              active-class="deep-purple--text text--accent-4"
              style="padding-left:8px"
            >
              <v-chip>20</v-chip>
              <v-chip>06</v-chip>
              <v-chip>2022</v-chip>
            </v-chip-group>
          </v-card>
        </v-col>
            </v-row>
            <v-row>        
              <v-col
                cols="12"
                md="12"
                sm="12"
              >
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th width="10%" class="text-left">
                          CANT.
                        </th>
                        <th width="80%" class="text-left">
                          DESCRIPCION
                        </th>
                        <th width="10%" class="text-left">
                          Importe
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in desserts"
                        :key="item.name"
                      >
                        <td class="text-left" >{{ item.calories }}</td>
                        <td class="text-left" >{{ item.name }}</td>
                        <td class="text-left" >{{ item.calories }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col
                cols="12"
                md="5"
                sm="12"
              >
              </v-col>
              <v-col
                cols="12"
                md="7"
                sm="12"
              >
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th width="40%" class="text-left">
                          GARANTIA: S/. 
                        </th>
                        <th width="30%" class="text-left">
                          
                        </th>
                        <th width="30%" class="text-left">
                          XXXXXXXXXXXX
                        </th>
                      </tr>
                      <tr>
                        <th width="40%" class="text-left">
                          D.N.I:
                        </th>
                        <th width="30%" class="text-left">
                          
                        </th>
                        <th width="30%" class="text-left">
                          XXXXXXXXXXXX
                        </th>
                      </tr>
                      <tr>
                        <th width="40%" class="text-left">
                          1ra. Prueba 
                        </th>
                        <th width="30%" class="text-left">
                          20 / 06 /2022
                        </th>
                        <th width="30%" class="text-left">
                          XXXXXXXXXXXX
                        </th>
                      </tr>
                      <tr>
                        <th width="40%" class="text-left">
                          2da. Prueba 
                        </th>
                        <th width="30%" class="text-left">
                          20 / 06 /2022
                        </th>
                        <th width="30%" class="text-left">
                          XXXXXXXXXXXX
                        </th>
                      </tr>
                      <tr>
                        <th width="40%" class="text-left">
                          3ra. Prueba 
                        </th>
                        <th width="30%" class="text-left">
                          20 / 06 /2022
                        </th>
                        <th width="30%" class="text-left">
                          XXXXXXXXXXXX
                        </th>
                      </tr>
                    </thead>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>            
          </section>  
      </section>
      </section> 
    </VueHtml2pdf> 
  </div>
</template>
<script>
import tabla from "@/components/contrato/tabla.vue"
import VueHtml2pdf from 'vue-html2pdf'

import { mapGetters, mapActions } from "vuex";
export default {
  name: "Contrato",
  components : {
    tabla,
    VueHtml2pdf
  },
  created(){
    const data = {menu1:2,menu2:0,submenu2:0,menu3:0,submenu3:0};
    this.CargarMenu(data);
    //this.cargarContrato();
  },
  data(){
    return{
      Cabecera:[
        {text:'Id',value:'id'},
        {text:'Valor',value:'valor'},
        {text:'Fecha',value:'fecha'},
        {text:'Estado',value:'estado'},
      ],
      htmlToPdfOptions: {
          margin: 0,
      
          filename: `hehehe.pdf`,
      
          image: {
              type: 'jpeg', 
              quality: 0.98
          },
      
          enableLinks: false,
      
          html2canvas: {
              scale: 1,
              useCORS: true
          },
      
          jsPDF: {
              unit: 'in',
              format: 'a4',
              orientation: 'portrait'
          }
      },
      desserts: [
          {
            name: 'Frozen Yogurt',
            calories: 159,
          },
          {
            name: 'Ice cream sandwich',
            calories: 237,
          },
          {
            name: 'Eclair',
            calories: 262,
          },
          {
            name: 'Cupcake',
            calories: 305,
          },
          {
            name: 'Gingerbread',
            calories: 356,
          },
          {
            name: 'Jelly bean',
            calories: 375,
          },
          {
            name: 'Lollipop',
            calories: 392,
          }
        ],
    }
  },
  methods:{
    //...mapActions('contrato',['cargarContrato']),
    ...mapActions('nav',['CargarMenu']),
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages()
          for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i)
              pdf.setFontSize(10)
              pdf.setTextColor(150)
              pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
          } 
      }).save()
    }
  },

  computed:{
    ...mapGetters('contrato',['Items'])    
  }


};
</script>




