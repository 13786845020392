import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Contrato from '../views/contrato/contrato.vue'
import ListaContrato from '../views/contrato/listacontrato.vue'
import TipoProducto from '../views/TipoProducto.vue'
import TipoContrato from '../views/TipoContrato.vue'
import Producto from '../views/Producto.vue'
import Cliente from '../views/Cliente.vue'
import Responsable from '../views/Responsable.vue'



Vue.use(VueRouter)
var existe = false; 
const routes = [
  
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    component: ListaContrato,
    meta: { requiresAuth: true },
  },
  {
    path: '/',
    name: 'listacontrato',
    component: ListaContrato,
    meta: { requiresAuth: true },
  },
  {
    path: '/contrato',
    name: 'contrato',
    component: Contrato,
    meta: { requiresAuth: true },
  },
  {
    path: '/listacontrato',
    name: 'listacontrato',
    component: ListaContrato,
    meta: { requiresAuth: true },
  },
  {
    path: '/tipoproducto',
    name: 'tipoproducto',
    component: TipoProducto,
    meta: { requiresAuth: true },
  },
  {
    path: '/tipocontrato',
    name: 'tipocontrato',
    component: TipoContrato,
    meta: { requiresAuth: true },
  },
  {
    path: '/producto',
    name: 'producto',
    component: Producto,
    meta: { requiresAuth: true },
  },
  {
    path: '/cliente',
    name: 'cliente',
    component: Cliente,
    meta: { requiresAuth: true },
  },
  {
    path: '/responsable',
    name: 'responsable',
    component: Responsable,
    meta: { requiresAuth: true },
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  //verificamos si la ruta que esta llegando existe en las rutas que tenemos 
  routes.forEach(element => {
    if(element.name == to.name){
      existe = true;
    }
  })

  if(existe){
    if(localStorage.getItem('logueo') && (to.name=="login" || to.name=="welcome") ){
      next('/home')
    }

    if(to.matched.some(route =>route.meta.requiresAuth)){
      //Verificamos que este logueado si no esta logueado redirecciona a login en caso contrario sigue normal 
      if(!localStorage.getItem('logueo')){        
        next('/login')
      }else{
        next()
      }
    }else{
      next()
    }
  }
})

export default router
