import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default{
  namespaced:true,
  state: {
    loggedIn : false,
    auth : [],
    //cliente : [],
  },
  getters: {
    loggedIn(state){
      return state.loggedIn
    }
  },
  mutations: {
    loggedIn(state){
      state.loggedIn = true,
      localStorage.setItem('logueo',true)
    },
    auth(state,data){
      state.auth = data
    }
  },
  actions: {
    mockLogin (context){
        context.commit('loggedIn')
    },
    registrocliente:async function({commit, dispatch},datos){
      try { 
        const  url = "storeCliente"
        await axios.post(url,datos)
        .then(response => {
          console.log(response.data)
          localStorage.setItem('token',response.data.token) 
          localStorage.setItem('name',response.data.name) 
          localStorage.setItem('razon',response.data.razon) 
          localStorage.setItem('idclient',response.data.id_cliente)
          localStorage.setItem('id',response.data.id)
          dispatch('mockLogin')
          window.location.reload()
          //commit('LlenarItems',response.data);
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("There was an error!", error);
        });        
      } catch (error) {
        
      }
    },
    login:async function({commit, dispatch},datos){
      console.log("data")
      try { 
        const  url = "login-api"
        await axios.post(url,datos)
        .then(response => {
          console.log(response.data)
          localStorage.setItem('id',response.data.id) 
          localStorage.setItem('token',response.data.token) 
          localStorage.setItem('name',response.data.name) 
          commit('auth',response.data)
          dispatch('mockLogin')
          window.location.reload();
          //router.push('/home')
          })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("There was an error!", error);
        });

      } catch (error) {
        
      }
    }

  }

}


