<template>
    <div>
        <v-row justify="center">
            <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
            >
            <v-card>
                <v-card-title>
                <span v-if="Item.PRO_Id == 0" class="text-h5">Nuevo Item del Contrato</span>
                <span v-else class="text-h5">Editar Item del Contrato</span>
                <v-spacer></v-spacer>
                <span v-if="Item.PRO_Id > 0" >ID: {{Item.PRO_Id}}</span>
                </v-card-title>
                <v-card-text>
                <v-container>
                    <!-- <FormularioDinamico :campos="campos"/> -->
                    <v-row>
                        <v-col
                            cols="12"
                            sm="6"
                            md="6"
                        >
                            <v-text-field
                            label="Descripcion *"
                            hide-details="auto"
                            readonly
                            type="text"
                            v-model="Item.descripcion"
                            required
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="6"
                        >
                            <v-text-field
                            label="Valor *"
                            hide-details="auto"
                            v-model="Item.CON_Precio"
                            required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <small>*indica los campos requeridos</small>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="error"
                    @click="cerrarDialogo()"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    color="info"
                    @click="agregar()"
                >
                    Guardar
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    props:['dialog','Item'],
    methods:{
        ...mapActions('contrato',['editarDetalle']),
        agregar(){
            this.editarDetalle(this.Item);
            this.cerrarDialogo();
            this.$alertify.success(this.Item.PRO_Id  == 0 ? "Agregado" : "Actualizado");
            this.$alertify.error("Se cancelo la acción")

        },
        cerrarDialogo(){
            this.$emit('dialog',false);
        }
    },
    data() {
        return {

        };
    },
}
</script>
