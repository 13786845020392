import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueAlertify from 'vue-alertify'
import VueHtml2pdf from 'vue-html2pdf'
import './interceptor/axios'
import './registerServiceWorker'



Vue.use(VueHtml2pdf);
Vue.use(VueAlertify);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
