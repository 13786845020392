<template>
    <div>
        <v-row justify="center">
            <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
            >
            <v-card>
                <v-card-title>
                <span v-if="Item.id == 0" class="text-h5">Nuevo Responsable</span>
                <span v-else class="text-h5">Editar Responsable</span>
                <v-spacer></v-spacer>
                <span v-if="Item.id > 0" >ID: {{Item.id}}</span>
                </v-card-title>
                <v-card-text>
                <v-container>
                    <!-- <FormularioDinamico :campos="campos"/> -->
                    <v-row>
                    <v-col
                        cols="12"
                        md="6"
                        sm="12"
                    >
                        <v-text-field
                        label="N° Documento *"
                        hide-details="auto"
                        v-model="Item.RES_NumeroDocumento"
                        :rules="rules"
                        counter
                        type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                        sm="12"
                    >
                        <v-text-field
                        label="Nombres *"
                        hide-details="auto"
                        v-model="Item.RES_Nombres"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                        sm="12"
                    >
                        <v-text-field
                        label="Apellidos *"
                        hide-details="auto"
                        v-model="Item.RES_Apellidos"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                        sm="12"
                    >
                        <v-text-field
                        label="Dirección *"
                        hide-details="auto"
                        v-model="Item.RES_Direccion"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                        sm="12"
                    >
                        <v-text-field
                        label="Email *"
                        hide-details="auto"
                        v-model="Item.RES_Email"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                        sm="12"
                    >
                        <v-text-field
                        label="Telefono *"
                        hide-details="auto"
                        v-model="Item.RES_Telefono"
                        type="number"
                        :rules="rulesCelular"
                        required
                        ></v-text-field>
                    </v-col>
                    </v-row>
                </v-container>
                <small>*indica los campos requeridos</small>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="error"
                    @click="cerrarDialogo()"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    color="info"
                    @click="agregar()"
                >
                    Guardar
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    props:['dialog','Item'],
    methods:{
        ...mapActions('responsable',['agregarResponsable',"cargarResponsable"]),
        agregar(){
            this.$alertify.confirm(
                this.Item.id  == 0 ? "Desea Agregar Item" : "Desea Editar Item", 
                () => {
                    this.agregarResponsable(this.Item);
                    this.cerrarDialogo();
                    this.$alertify.success(this.Item.id  == 0 ? "Agregado" : "Actualizado");
                    }, 
                () => this.$alertify.error("Se cancelo la acción")
            ).setHeader('<em> Aviso </em> ');
        },
        cerrarDialogo(){
            this.cargarResponsable;  
            this.$emit('dialog',false);
        }
    },
    data() {
        return {
            rules: [
                    v => !!v || 'Requerido',
                    v => v.length >= 8 || '8 digitos obligatorio',
                    v => v.length <= 11 || '11 digitos obligatorio',
                ],
            rulesCelular: [
                    v => !!v || 'Requerido',
                    v => v.length >= 9 || '9 digitos obligatorio',
                    v => v.length <= 11 || '11 digitos obligatorio',
                ]

        };
    },
}
</script>
