import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

var token = localStorage.getItem('token')
export default{
  namespaced:true,
  state: {
    Items : [],
    ItemsTipo : [],
    ItemIdProductoEnd : 0
  },
  getters: {
    Items(state){
      return state.Items
    },
    ItemsTipo(state){
      return state.ItemsTipo
    },
    ItemIdProductoEnd(state){
      return state.ItemIdProductoEnd
    }
    
  },
  mutations: {
    LlenarItems(state,data){
      state.Items = data
    },
    LlenarItemsTipo(state,data){
      state.ItemsTipo = data
    },
    LlenarItemIdProductoEnd(state,data){
      state.ItemIdProductoEnd = data
    }
    
  },
  actions: {
    cargarProducto:async function({commit}){
      try {
        const  url = "producto"
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        await axios.get(url,config)
        .then(response => {
          var arraytipo = []
          var con = 0
          response.data.success.resultado.tipo_producto.forEach(element => {
              arraytipo[con] = element.TIP_Nombre
              con++ 
          });
          commit('LlenarItems',response.data.success.resultado.producto);     
          commit('LlenarItemsTipo',arraytipo);     
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
        }); 
      } catch (error) {
        console.log(error)
      }
    },    
    agregarProducto: async function({commit,dispatch},datos){
      var  url = "producto/store"
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      await axios.post(url,datos,config)
      .then(response => {
        commit('LlenarItemIdProductoEnd',response.data.success.resultado.id);     
        dispatch('cargarProducto')     
      })
      .catch(error => {
        this.errorMessage = error.response.data;
        console.error("error!", error);
      }); 
    },
    eliminarProducto: async function({commit,dispatch},datos){

      const  url = `producto/${datos.id}`
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      await axios.delete(url,config)
      .then(response => { 
        dispatch('cargarProducto')     
      })
      .catch(error => {
        this.errorMessage = error.response.data;
        console.error("error!", error);
      }); 
    },     
  }

}


