import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default{
  namespaced:true,
  state: {
    Datos : [],
    Menu0 : 0,
    SubMenu0 : 0,
    Menu1 : 0,
    Menu2 : 0,
    SubMenu2 : 0,
    Menu3 : 0,
    SubMenu3 : 0,
    Mini : false
  },
  getters: {
    Datos(state){
      return state.Datos
    },
    Menu0(state){
      return state.Menu0
    },
    SubMenu0(state){
      return state.SubMenu0
    },
    Menu1(state){
      return state.Menu1
    },
    Menu2(state){
      return state.Menu2
    },
    SubMenu2(state){
      return state.SubMenu2
    },
    Menu3(state){
      return state.Menu3
    },
    SubMenu3(state){
      return state.SubMenu3
    },
    Mini(state){
      return state.Mini
    },
    Drawer(state){
      return state.Drawer
    }
    
  },
  mutations: {
    LlenarDatos(state,data){
      state.Datos = data
    },
    LlenarMenu0(state,data){
      state.Menu0 = data
    },
    LlenarSubMenu0(state,data){
      state.SubMenu0 = data
    },
    LlenarMenu1(state,data){
      state.Menu1 = data
    },
    LlenarMenu2(state,data){
      state.Menu2 = data
    },
    LlenarSubMenu2(state,data){
      state.SubMenu2 = data
    },
    LlenarMenu3(state,data){
      state.Menu3 = data
    },
    LlenarSubMenu3(state,data){
      state.SubMenu3 = data
    },
    LlenarMini(state,data){
      state.Mini = data
    },
    LlenarDrawer(state,data){
      state.Drawer = data
    }
  },
  actions: {
    CargarRutas:async function({commit}){
      try {
        const data = {idcliente: localStorage.getItem('idclient') };  
        const  url = "listarutas"
        await axios.post(url,data)
        .then(response => {
          var array = []
          var dataaux = []
          array = [{
                icon: 'mdi-home',
                text: 'Home',
                ruta:'home',
                path:'/home'
              },
              {
                icon: 'mdi-chart-donut',
                text: 'Llantas',
                ruta:'imagenes',
                path:'/imagenes'
              },
              {
                icon: 'mdi-dialpad',
                text: 'Marcas',
                ruta:'excel',
                path:'/excel'
              }]
          var tamaño =  array.length;
          var con = 0;
          for(var i=0 ; i < tamaño; i++){
            if(i >0){
              var entro = 'false';
              for(var j=0 ; j < response.data.length; j++){
                if(response.data[j].PLU_Ruta == array[i].text){
                  entro = 'true'
                }
              } 
              if(entro == 'true'){
                dataaux[con]=array[i]
                con++
              }
            }else{
              dataaux[con]=array[i]  
              con++
            }
          }  
          commit('LlenarDatos',dataaux);
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          console.error("There was an error!", error);
        });        
      } catch (error) {
        
      }
    },
    CargarMenu({commit,dispatch},data){  
      commit('LlenarMenu0',data.menu0);
      commit('LlenarSubMenu0',data.submenu0);
      commit('LlenarMenu1',data.menu1);
      commit('LlenarMenu2',data.menu2);
      commit('LlenarSubMenu2',data.submenu2);
      commit('LlenarMenu3',data.menu3);
      commit('LlenarSubMenu3',data.submenu3);
    },
    Minimizar({commit,state,dispatch},data){ 
      if(state.Drawer){
        commit('LlenarDrawer',true);
        commit('LlenarMini',data);
      }else{
        commit('LlenarDrawer',true);
      }
    }
  }

}


