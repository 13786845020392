<template>
<div>
  <body>
    <v-card
      class="mx-auto"
      max-width="1800"
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
            <v-card  >
              <v-toolbar
                dark
                dense
                color="accent"
              >
                <v-autocomplete
                  dense
                  @change="mostrarDetalleResponsable"
                  v-model="select"
                  :loading="loading"
                  :items="ItemsResponsable"
                  :search-input.sync="search"
                  cache-items
                  class="mx-4"
                  flat
                  hide-no-data
                  hide-details
                  label="Buscar resposable registrado"
                  solo-inverted
                ></v-autocomplete>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        x-small
                        outlined
                        fab
                        v-bind="attrs"
                        v-on="on"
                        color="white"
                        @click="NuevoResponsable()"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Nuevo Responsable</span>
                  </v-tooltip>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-text-field
                    type="hidden"
                    dense
                    label="Nombre *"
                    hide-details="auto"
                    v-model="Item.RES_Id"
                    required
                    ></v-text-field>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-text-field
                    dense
                    label="Nombres *"
                    hide-details="auto"
                    v-model="Item.RES_Nombres"
                    :readonly="habilitarCampoResponsable"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-text-field
                    :readonly="habilitarCampoResponsable"
                    dense
                    label="Apellidos *"
                    hide-details="auto"
                    v-model="Item.RES_Apellidos"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-text-field
                    :readonly="habilitarCampoResponsable"
                    dense
                    prepend-icon="mdi-card"
                    label="N° Documento *"
                    hide-details="auto"
                    v-model="Item.RES_NumeroDocumento"
                    counter
                    type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-text-field
                    :readonly="habilitarCampoResponsable"
                    dense
                    prepend-icon="mdi-map-marker"
                    label="Dirección *"
                    hide-details="auto"
                    v-model="Item.RES_Direccion"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-text-field
                    :readonly="habilitarCampoResponsable"
                      dense
                      prepend-icon="mdi-account"
                      v-model="Item.RES_Email"
                      name="email"
                      label="Email"
                      type="text"
                      placeholder="Email"
                      required
                      hide-details="auto"
                    >
                      <template v-slot:prepend>
                        <v-tooltip
                          bottom
                        >
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                              @
                            </v-icon>
                          </template>
                          Correo
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-text-field
                    :readonly="habilitarCampoResponsable"
                    dense
                    label="Telefono *"
                    hide-details="auto"
                    v-model="Item.RES_Telefono"
                    type="number"
                    prepend-icon="mdi-phone"
                    required
                    >
                    </v-text-field>
                  </v-col>

                </v-row>
              </v-card-text>  
            </v-card>
            </v-form> 

            <v-card ref="form">
              <v-toolbar
                dark
                dense
                color="accent"
              >
                <v-toolbar-title><h5>Contrato</h5></v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        v-model="date"
                        label="Fecha de Contrato"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  </v-col>
                  <v-col
                      cols="12"
                      md="6"
                      sm="12"
                  >
                    <v-autocomplete   
                        :items="ItemsTipoContrato"
                        v-model="Item.TIC_Id"
                        label="Tipo Contrato *"
                        dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                  <v-menu
                    ref="menucon"
                    v-model="menucon"
                    :close-on-content-click="false"
                    :return-value.sync="Item.CON_Entrega"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        v-model="Item.CON_Entrega"
                        label="Fecha de Entrega"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="Item.CON_Entrega"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="menucon = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menucon.save(Item.CON_Entrega)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                  <v-menu
                    ref="menudev"
                    v-model="menudev"
                    :close-on-content-click="false"
                    :return-value.sync="Item.CON_Devolucion"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        v-model="Item.CON_Devolucion"
                        label="Fecha de Devolución"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="Item.CON_Devolucion"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="menudev = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menudev.save(Item.CON_Devolucion)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  </v-col>

                  <v-row
                  class="pl-3 pr-3">
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                    >
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :return-value.sync="Item.CON_Fecha1"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            v-model="Item.CON_Fecha1"
                            label="Fecha 1ra Prueba"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="Item.CON_Fecha1"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="menu1 = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu1.save(Item.CON_Fecha1)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                    >
                      <v-text-field
                      dense
                      prefix="$"
                      placeholder="0.00"
                      label="1ra Adelanto"
                      hide-details="auto"
                      v-model="Item.CON_Adelanto1"
                      required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                    >
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :return-value.sync="Item.CON_Fecha2"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            v-model="Item.CON_Fecha2"
                            label="Fecha 2da Prueba"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="Item.CON_Fecha2"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="menu2 = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu2.save(Item.CON_Fecha2)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                    >
                      <v-text-field
                      dense
                      prefix="$"
                      placeholder="0.00"
                      label="2do Adelanto"
                      hide-details="auto"
                      v-model="Item.CON_Adelanto2"
                      required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                    >
                      <v-menu
                        ref="menu3"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :return-value.sync="Item.CON_Fecha3"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            v-model="Item.CON_Fecha3"
                            label="Fecha 3ra Prueba"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="Item.CON_Fecha3"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="menu3 = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu3.save(Item.CON_Fecha3)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                    >
                      <v-text-field
                      dense
                      prefix="$"
                      placeholder="0.00"
                      label="3ra Adelanto"
                      hide-details="auto"
                      v-model="Item.CON_Adelanto3"
                      required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                    dense
                    label="Descripcion de documento y garantía *"
                    hide-details="auto"
                    v-model="Item.CON_Descripcion"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-text-field
                    dense
                    prefix="$"
                    placeholder="0.00"
                    label="Adelanto *"
                    hide-details="auto"
                    v-model="Item.CON_Garantia"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-text-field
                    dense
                    prefix="$"
                    placeholder="0.00"
                    label="Pago Efectuado *"
                    hide-details="auto"
                    v-model="Item.CON_Pago"
                    required
                    ></v-text-field>
                  </v-col>

                </v-row>
              </v-card-text>  
            </v-card>
            <v-card ref="form">
              <v-toolbar
                dark
                dense
                color="accent"
              >
                <v-toolbar-title><h5>Medidas</h5></v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                  >
                    <v-autocomplete   
                        :items="ItemsCliente"
                        v-model="Item.CLI_Id"
                        label="Seleccionar Cliente *"
                        @change="mostrarDetalleCliente"
                        dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                    md="2"
                  >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        x-small
                        outlined
                        fab
                        v-bind="attrs"
                        v-on="on"
                        color="teal"
                        @click="NuevoCliente()"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Nuevo Cliente</span>
                  </v-tooltip>
                </v-col>  
                  <v-col
                      v-if="habilitarCliente == true"
                      cols="12"
                      md="12"
                      sm="12"
                  >
                      <v-text-field
                      dense
                      label="Nombre Cliente*"
                      hide-details="auto"
                      v-model="Item.CLI_Nombre"
                      :readonly="habilitarCampoCliente"
                      required
                      ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    dense
                    label="Busto :"
                    hide-details="auto"
                    v-model="Item.DCM_Busto"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    dense
                    label="Cintura :"
                    hide-details="auto"
                    v-model="Item.DCM_Cintura"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    dense
                    label="Espalda :"
                    hide-details="auto"
                    v-model="Item.DCM_Espalda"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    dense
                    label="Cuello :"
                    hide-details="auto"
                    v-model="Item.DCM_Cuello"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    dense
                    label="Sisa :"
                    hide-details="auto"
                    v-model="Item.DCM_Sisa"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    dense
                    label="Talle :"
                    hide-details="auto"
                    v-model="Item.DCM_Talla"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    dense
                    label="A. Busto :"
                    hide-details="auto"
                    v-model="Item.DCM_ABusto"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    dense
                    label="Cadera :"
                    hide-details="auto"
                    v-model="Item.DCM_Cadera"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    dense
                    label="A. Cadera :"
                    hide-details="auto"
                    v-model="Item.DCM_ACadera"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    dense
                    label="L. Falda :"
                    hide-details="auto"
                    v-model="Item.DCM_LFalda"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    dense
                    label="L. Manga :"
                    hide-details="auto"
                    v-model="Item.DCM_LManga"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    dense
                    label="C. Manga :"
                    hide-details="auto"
                    v-model="Item.DCM_CManga"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    dense
                    label="C. Puño :"
                    hide-details="auto"
                    v-model="Item.DCM_CPuño"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    dense
                    label="A. Bajo Busto :"
                    hide-details="auto"
                    v-model="Item.DCM_ABajoBusto"
                    required
                    ></v-text-field>
                  </v-col>

                </v-row>
              </v-card-text>  
            </v-card>
          </v-col>
          
            <v-col
              cols="12"
              sm="6"
              md="8"
            >
              <v-card ref="form">
                <v-toolbar
                  dark
                  dense
                  color="accent"
                >
                  <v-toolbar-title><h5>Producto</h5></v-toolbar-title>
                  <v-autocomplete
                    dense
                    v-model="select2"
                    :loading="loading2"
                    :items="ItemsProducto"
                    :search-input.sync="search2"
                    @change="mostrarDetalleProducto"
                    cache-items
                    class="mx-4"
                    flat
                    hide-no-data
                    hide-details
                    label="Buscar Producto"
                    solo-inverted
                  ></v-autocomplete>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        small
                        outlined
                        fab
                        v-bind="attrs"
                        v-on="on"
                        color="white"
                        @click="NuevoProducto()"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Nuevo Producto</span>
                  </v-tooltip>
                </v-toolbar>
                <v-card-text>
                <v-row>
                  <v-text-field
                    type="hidden"
                    dense
                    label="Nombre *"
                    hide-details="auto"
                    v-model="Item.PRO_Id"
                    required
                    ></v-text-field>
                  <v-col
                      cols="12"
                      md="6"
                      sm="12"
                  >
                    <v-autocomplete   
                        :items="ItemsTipoProducto"
                        v-model="Item.TIP_Id"
                        :readonly="habilitarProducto"
                        label="Tipo *"
                        dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                      cols="12"
                      md="6"
                      sm="12"
                  >
                      <v-text-field
                      dense
                      label="Nombre *"
                      hide-details="auto"
                      v-model="Item.PRO_Nombre"
                      :readonly="habilitarProducto"
                      required
                      ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      md="6"
                      sm="12"
                  >
                    <v-text-field
                    dense
                    label="Color *"
                    hide-details="auto"
                    v-model="Item.PRO_Color"
                      :readonly="habilitarProducto"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      md="6"
                      sm="12"
                  >
                      <v-text-field
                      dense
                      label="Encaje"
                      hide-details="auto"
                      v-model="Item.PRO_Encaje"                        
                      :readonly="habilitarProducto"
                      ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      md="3"
                      sm="6"
                  >
                      <v-text-field
                      dense
                      label="Tela *"
                      hide-details="auto"
                      v-model="Item.PRO_Tipo_Tela"
                      :readonly="habilitarProducto"
                      required
                      ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      md="3"
                      sm="6"
                  >
                      <v-text-field
                      dense
                      label="Precio *"
                      hide-details="auto"
                      v-model="Item.CON_Precio"
                      type="number"
                      required
                      ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      md="3"
                      sm="6"
                  >
                      <v-text-field
                      dense
                      label="Cantidad *"
                      hide-details="auto"
                      v-model="Item.CON_Cantidad"
                      type="number"
                      required
                      ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      md="3"
                      sm="6"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mx-2"
                          @click="CargarDetalle"
                          fab
                          small
                          dark
                          color="success"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon dark>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Añadir detalle al contrato</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row>                  
                  <v-col
                      cols="12"
                      md="12"
                      sm="12"
                  >
                    <DetalleTabla  />
                    <div class="text-center">
                      <v-btn
                        v-if="Item.id == 0"
                        class="ma-2"
                        :loading="loadingBtn"
                        :disabled="loadingBtn"
                        color="success"
                        @click="GuardarContrato"
                      >
                        Generar Contrato
                        <template v-slot:loader>
                          <span>Loading...</span>
                        </template>
                      </v-btn>
                      <v-btn
                        v-else
                        class="ma-2"
                        :loading="loadingBtn"
                        :disabled="loadingBtn"
                        color="success"
                        @click="GuardarContrato"
                      >
                        Modificar  Contrato
                        <template v-slot:loader>
                          <span>Loading...</span>
                        </template>
                      </v-btn>
                    </div>

                  </v-col>
                </v-row>
              </v-card-text>  

            </v-card>
          </v-col>
        </v-row>
        
      </v-card-text>
    </v-card>  
  </body>
  
  <dialogalert :color="color" :icono="icono" :titulo="titulo" :mensaje="mensaje" :dialog="dialog" @dialog="dialog=$event" />
  </div>
</template>
<script>
import DetalleTabla from '@/components/contrato/detalletabla.vue'
import dialogalert from '@/components/dialogalert.vue'
import { mapGetters, mapActions } from "vuex";
  export default {
    data () {
      return {
        Item : {
          id:0,
          PRO_Id:"",
          TIP_Id:"",
          PRO_Nombre:"",
          PRO_Color:"",
          PRO_Encaje:"",
          PRO_Tipo_Tela:"",
          CON_Cantidad:"",
          RES_Id : 0,
          RES_Nombres : "",
          RES_Apellidos : "",
          RES_NumeroDocumento : "",
          RES_Direccion : "",
          RES_Email : "",
          RES_Telefono : "",
        },
        valid: true,
        dialog: false,
        color: '',
        icono: '',
        titulo: '',
        mensaje: '',
        habilitarProducto:true,
        habilitarCliente:false,
        habilitarCampoCliente:true,
        habilitarCampoResponsable:true,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        loading: false,
        loading2: false,
        loadingBtn: false,
        loader: null,
        search: null,
        search2: null,
        select: null,
        select2: null,
        menu:false,
        menucon:false,
        menudev:false,
        menu1:false,
        menu2:false,
        menu3:false,
        states: [
        ],
        states2: [
          
        ],
        /* rules: [
                v => !!v || 'Requerido',
                v => v.length >= 8 || '8 digitos obligatorio',
                v => v.length <= 11 || '11 digitos obligatorio',
            ],
        rulesCelular: [
                v => !!v || 'Requerido',
                v => v.length >= 9 || '9 digitos obligatorio',
                v => v.length <= 11 || '11 digitos obligatorio',
            ],
        email: '',
        emailRules: [
          v => !!v || 'E-mail es requerido',
          v => /.+@.+\..+/.test(v) || 'E-mail no es valido',
        ], */
      }
    },
    watch: {
      search (val) {
        val && val !== this.select && this.querySelections(val)
      },
      search2 (val) {
        val && val !== this.select2 && this.querySelections2(val)
      },
    },
    methods: {
      querySelections (v) {
        this.loading = true
        setTimeout(() => {
          /* this.ItemsResponsable = this.states.filter(e => {
            return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
          }) */
          this.loading = false
        }, 500)
      },
      querySelections2 (v) {
        this.loading2 = true
        setTimeout(() => {
          /* this.ItemsProducto = this.states2.filter(e => {
            return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
          }) */
          this.loading2 = false
        }, 500)
      },
      mostrarDetalleProducto(){
        this.ItemsAllProducto.forEach(element => {
          if(element.id == this.select2){
            this.Item.PRO_Id = element.id
            this.Item.TIP_Id = parseInt(element.TIP_Id)
            this.Item.PRO_Nombre = element.PRO_Nombre
            this.Item.PRO_Color = element.PRO_Color
            this.Item.PRO_Encaje = element.PRO_Encaje
            this.Item.PRO_Tipo_Tela = element.PRO_Tipo_Tela
            this.Item.CON_Cantidad = 1
            this.select2=""
            this.search2=""
          }
        });
      },
      mostrarDetalleResponsable(){
        this.ItemsAllResponsable.forEach(element => {
          if(element.id == this.select){
            this.Item.RES_Id = element.id
            this.Item.RES_Nombres = element.RES_Nombres
            this.Item.RES_Apellidos = element.RES_Apellidos
            this.Item.RES_NumeroDocumento = element.RES_NumeroDocumento
            this.Item.RES_Direccion = element.RES_Direccion
            this.Item.RES_Email = element.RES_Email
            this.Item.RES_Telefono = element.RES_Telefono
            this.select=""
          }
        });
      },
      mostrarDetalleCliente(){
        this.ItemsAllCliente.forEach(element => {
          if(element.id == this.Item.CLI_Id){
            this.Item.CLI_Nombre = element.CLI_Nombre
            this.habilitarCliente = true
          }
        });
      },
      CargarDetalle(){
        var datos;
        if(this.Item.PRO_Id == 0){
          var Productos = {
            id : this.Item.PRO_Id,
            TIP_Id : this.Item.TIP_Id,
            PRO_Nombre : this.Item.PRO_Nombre,
            PRO_Color : this.Item.PRO_Color,
            PRO_Encaje : this.Item.PRO_Encaje,
            PRO_Tipo_Tela : this.Item.PRO_Tipo_Tela,
          }
          this.agregarProducto(Productos)
          .then(r => {
            this.cargarProducto();
            datos = {
              PRO_Id : this.ItemIdProductoEnd,
              descripcion : this.Item.PRO_Nombre+ "  "+this.Item.PRO_Color+"  "+this.Item.PRO_Encaje,
              CON_Cantidad : this.Item.CON_Cantidad,
              CON_Precio : this.Item.CON_Precio
            }
            this.Limpiar()
            this.cargarDetalleTabla(datos)
          });
        }else{
          datos = {
            PRO_Id : this.Item.PRO_Id,
            descripcion : this.Item.PRO_Nombre+ "  "+this.Item.PRO_Color+"  "+this.Item.PRO_Encaje,
            CON_Cantidad : this.Item.CON_Cantidad,
            CON_Precio : this.Item.CON_Precio
          }
          this.Limpiar()
          this.cargarDetalleTabla(datos)
        }
        
        
      },
      Limpiar(){
        this.Item.PRO_Id = 0
        this.Item.TIP_Id = ""
        this.Item.PRO_Nombre = ""
        this.Item.PRO_Color = ""
        this.Item.PRO_Encaje = ""
        this.Item.PRO_Tipo_Tela = ""
        this.Item.CON_Cantidad = 1
        this.Item.CON_Precio = ""        
      },
      NuevoProducto(){
        this.Limpiar()
        this.select2= ""
        this.search2=""
        this.habilitarProducto = false
      },
      NuevoCliente(){
        this.Item.CLI_Id = 0
        this.Item.CLI_Nombre = ""
        this.habilitarCliente = true
        this.habilitarCampoCliente = false
      },
      NuevoResponsable(){
        this.Item.RES_Id = 0
        this.Item.RES_Nombres = ""
        this.Item.RES_Apellidos = ""
        this.Item.RES_NumeroDocumento = ""
        this.Item.RES_Direccion = ""
        this.Item.RES_Email = ""
        this.Item.RES_Telefono = ""
        this.habilitarCampoResponsable = false
      },      
      GuardarContrato(){
        var responsable = {
          "id" : this.Item.RES_Id,
          "RES_Nombres" : this.Item.RES_Nombres,
          "RES_Apellidos" : this.Item.RES_Apellidos,
          "RES_NumeroDocumento" : this.Item.RES_NumeroDocumento,
          "RES_Direccion" : this.Item.RES_Direccion,
          "RES_Email" : this.Item.RES_Email,
          "RES_Telefono" : this.Item.RES_Telefono,
        }
        var detalle_medida = {
          "CLI_Id" : this.Item.CLI_Id,
          "CLI_Nombre" : this.Item.CLI_Nombre,
          "DCM_Busto" : this.Item.DCM_Busto,
          "DCM_Cintura" : this.Item.DCM_Cintura,
          "DCM_Espalda" : this.Item.DCM_Espalda,
          "DCM_Cuello" : this.Item.DCM_Cuello,
          "DCM_Sisa" : this.Item.DCM_Sisa,
          "DCM_Talla" : this.Item.DCM_Talla,
          "DCM_ABusto" : this.Item.DCM_ABusto,
          "DCM_Cadera" : this.Item.DCM_Cadera,
          "DCM_ACadera" : this.Item.DCM_ACadera,
          "DCM_LFalda" : this.Item.DCM_LFalda,
          "DCM_LManga" : this.Item.DCM_LManga,
          "DCM_CManga" : this.Item.DCM_CManga,
          "DCM_CPuño" : this.Item.DCM_CPuño,
          "DCM_ABajoBusto" : this.Item.DCM_ABajoBusto
        }
        if(this.Item.id != 0 ){
          var contrato = {
            "id" : this.Item.id,
            "CON_Fecha" : this.date,
            "TIC_Id" : this.Item.TIC_Id,
            "CON_Garantia" : this.Item.CON_Garantia,
            "CON_Pago" : this.Item.CON_Pago,
            "CON_Entrega" : this.Item.CON_Entrega,
            "CON_Devolucion" : this.Item.CON_Devolucion,
            "CON_Fecha1" : this.Item.CON_Fecha1,
            "CON_Fecha2" : this.Item.CON_Fecha2,
            "CON_Fecha3" : this.Item.CON_Fecha3,
            "CON_Adelanto1" : this.Item.CON_Adelanto1,
            "CON_Adelanto2" : this.Item.CON_Adelanto2,
            "CON_Adelanto3" : this.Item.CON_Adelanto3,
            "CON_Descripcion" : this.Item.CON_Descripcion
          }
        }else{
          var contrato = {
            "id" : 0,
            "CON_Fecha" : this.date,
            "TIC_Id" : this.Item.TIC_Id,
            "CON_Garantia" : this.Item.CON_Garantia,
            "CON_Pago" : this.Item.CON_Pago,
            "CON_Entrega" : this.Item.CON_Entrega,
            "CON_Devolucion" : this.Item.CON_Devolucion,
            "CON_Fecha1" : this.Item.CON_Fecha1,
            "CON_Fecha2" : this.Item.CON_Fecha2,
            "CON_Fecha3" : this.Item.CON_Fecha3,
            "CON_Adelanto1" : this.Item.CON_Adelanto1,
            "CON_Adelanto2" : this.Item.CON_Adelanto2,
            "CON_Adelanto3" : this.Item.CON_Adelanto3,
            "CON_Descripcion" : this.Item.CON_Descripcion
          }
        }
        
        var detalle_tabla = this.ItemsDetalleTabla;
        var datos={"responsable":responsable, "detalle_medida":detalle_medida, "contrato":contrato, "detalle_tabla":detalle_tabla}
        this.loader = 'loadingBtn'
        const l = this.loader
        this[l] = !this[l]
        if(responsable.RES_Nombres != "" && responsable.RES_Apellidos != ""  && responsable.RES_NumeroDocumento != "" ){
          if(this.ItemsDetalleTabla.length > 0){
            this.$alertify.confirm(
                this.Item.id  == 0 ? "Desea Agregar Contrato" : "Desea Editar COntrato con Item", 
                () => {
                    this.agregarContrato(datos); 
                    this.$alertify.success(this.Item.id  == 0 ? "Agregado" : "Actualizado");
                    //this.$router.replace({ path: '/listacontrato' })
                    }, 
                () => this.$alertify.error("Se cancelo la acción")
            ).setHeader('<em> Aviso </em> ');
          }else{
            this.error("Aun no a añadido al carrito el producto")            
          }
        }else{
            this.error("Seleccionar Responsable o Llenar los Datos de responsable")  
        }
        
        this[l] = false
        this.loader = null
      },
      error(mensaje){
        this.color = 'error',
        this.icono = 'mdi-cloud-alert',
        this.titulo =  'Error al Guardar Contrato',
        this.mensaje = mensaje          
        this.dialog = true
      },
      ...mapActions('contrato',['cargarResponsable','cargarCliente','cargarProducto','cargarDetalleTabla','agregarContrato']),
      ...mapActions('producto',['agregarProducto']),      
      ...mapActions('nav',['CargarMenu'])
    },
    created(){
      const data = {menu0:1,submenu0:0};
      this.CargarMenu(data);
      this.cargarResponsable();
      this.cargarProducto();
      this.cargarCliente();
      if(this.ItemsPdfContrato.id && this.ItemsPdfContrato.id != 0){
        this.Item = this.ItemsPdfContrato
        this.date = this.Item.CON_Fecha
      }

    },
    computed:{
      ...mapGetters('contrato',['ItemsResponsable','ItemsProducto','ItemsCliente','ItemsAllCliente','ItemsTipoContrato','ItemsTipoProducto',
            'ItemsAllResponsable','ItemsAllProducto','ItemsDetalleTabla','ItemsPdfContrato']),
      ...mapGetters('producto',['ItemIdProductoEnd'])    
    },
    components:{
      DetalleTabla,
      dialogalert
    }
  }
</script>

<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>