<template>
  <v-parallax
    dark
    height="680"
    src="/imagen_login.jpg"
  >
    <v-container fluid fill-height text-center>
      <v-layout align-center justify-center pa-10 style="margin-top:1%;margin-bottom:1%;">
        <v-flex xs12 sm9 md6 >
          <v-card class="elevation-12 rounded-xl pa-4" >
            <!-- <img style="width: 170px;" src="/logo.png"> -->
            <img style="width: 170px;" src="/logo.jpeg">
            <br>
            {{
              isRegister ? stateObj.register.name : stateObj.login.name
              }}
            <v-card-text>
              <v-form
                ref="form"
                lazy-validation
                @submit.prevent="isRegister ? register() : validate()"
              >
                <v-text-field
                  v-if="isRegister"
                  v-model="Empresa"
                  label="Nombre de la Empresa"
                  hide-details="auto"
                  prepend-icon="mdi-account"
                ></v-text-field>
                <v-text-field
                  v-if="isRegister"
                  v-model="Nombre"
                  label="Nombre del Cliente"
                  hide-details="auto"
                  prepend-icon="mdi-account"
                ></v-text-field>
                <v-text-field
                  v-if="isRegister"
                  v-model="Telefono"
                  label="Telefono"
                  hide-details="auto"
                  prepend-icon="mdi-phone"
                ></v-text-field>
                <v-text-field
                  v-if="isRegister"
                  prepend-icon=" mdi-email"
                  v-model="email"
                  :rules="emailRules"
                  name="email"
                  label="Email"
                  type="text"
                  placeholder="Email"
                  required
                  hide-details="auto"
                ></v-text-field>
                <v-text-field
                  v-else
                  prepend-icon="mdi-account"
                  v-model="email"
                  :rules="emailRules"
                  name="email"
                  label="Email"
                  type="text"
                  placeholder="Email"
                  required
                  hide-details="auto"
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  prepend-icon="mdi-lock"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Contraseña"
                  hint="Al menos 6 caracteres"
                  counter
                  @click:append="show1 = !show1"
                ></v-text-field>

                <v-text-field
                  v-if="isRegister"
                  prepend-icon="mdi-lock"
                  v-model="confirmPassword"
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  placeholder="Confirmar Contraseña"
                  required
                ></v-text-field>
                <v-checkbox v-if="isRegister" v-model="checkbox">
                  <template v-slot:label>
                    <div>
                      He leído y acepto los
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            target="_blank"
                            href="https://vuetifyjs.com"
                            @click.stop
                            v-on="on"
                          >
                            Terminos de uso
                          </a>
                        </template>
                        Ver Terminos de Uso
                      </v-tooltip>
                      ***
                    </div>
                  </template>
                </v-checkbox>
                <div class="red--text text--lighten-1 text-body-2 mb-4">
                  {{ errorMessage }}
                </div>
                <v-btn
                  block
                  dark
                  type="submit"
                  class="mt-4"
                  color="blue-grey lighten-2"
                  value="log in"
                  :disabled="!valid"
                  submit
                  >{{
                    isRegister
                      ? stateObj.register.namebtn
                      : stateObj.login.namebtn
                  }}</v-btn
                >
                <!-- <div class="grey--text mt-4" color="blue accent-4" v-on:click="isRegister = !isRegister;">
                        {{toggleMessage}}  
                      </div> -->
                <div v-if="!isRegister" class="pa-2">
                    ¿Olvidaste tu
                    <a color="blue accent-4" @click="insertarRuta('/registro')">
                      contraseña </a
                    >?
                    <!-- <br />
                    ¿Aun no tienes una cuenta ?
                    <a color="blue accent-4" @click="CambioPantalla() ">
                      Registrate
                    </a> -->
                </div>
                <div v-else class="pa-2">
                  <a
                    color="blue accent-4"
                    @click="CambioPantalla() "
                  >
                    ¿Ya tienes una cuenta?
                  </a>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-parallax>
</template>
<script>
import axios from 'axios'
import { mapActions } from 'vuex';
export default {
  name: "App",
  data() {
    return {
      valid: true,
      checkbox: true,
      username: "",
      confirmPassword: "",
      isRegister: false,
      errorMessage: "",
      stateObj: {
        register: {
          name: "Registrar Usuario",
          namebtn: "Registrar",
          message: "¿Ya tienes una cuenta?",
        },
        login: {
          name: "",
          namebtn: "Ingresar",
          message: "¿Ahún no tienes una cuenta ? Registrate",
        },
      },
      valid: true,
      email: '',
      emailRules: [
        v => !!v || 'E-mail es requerido',
        v => /.+@.+\..+/.test(v) || 'E-mail no es valido',
      ],
      show1: false,
      password: '',
      rules: {
        required: v => !!v || 'Requerido.',
        min: v => v.length >= 6 || 'Min 6 characters'
      },
    };
  },
  created(){
  },
  methods: {
    register() {
      if (this.password == this.confirmPassword) {
        if(this.$refs.form.validate()){
          const data = {Empresa: this.Empresa,name: this.Nombre,Telefono: this.Telefono,email: this.email, password: this.password};
          this.registrocliente(data)
        }
      } else {
        this.errorMessage = "las contraseñas no coinciden";
      }
    },
    async validate () {
      if(this.$refs.form.validate()){
          const data = {email: this.email, password: this.password};
          this.login(data)
      }      
    },
    CambioPantalla(){
      this.isRegister = !this.isRegister
      this.$refs.form.resetValidation()
    },
    ...mapActions('login',['mockLogin','registrocliente','login'])
  },
  computed: {
    toggleMessage: function () {
      return this.isRegister
        ? this.stateObj.register.message
        : this.stateObj.login.message;
    },
  },
};
</script>
