<template>
  <div style="margin-top:30px">
    <tabla  Titulo="Lista Contrato" :Cabecera="Cabecera" :Items="Items" :Excel="false" :Crear="true" />
  </div>
</template>
<script>
import tabla from "@/components/contrato/tabla.vue"
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Responsable",
  components : {
    tabla
  },
  created(){
    const data = {menu0:1,submenu0:1,menu1:-1,menu2:0,submenu2:0,menu3:0,submenu3:0};
    this.CargarMenu(data);
    this.cargarContrato();
  },
  data(){
    return{
      Cabecera:[
        {text:'Id',value:'id'},
        {text:'Tipo',value:'tipo'},
        {text:'N° Documento',value:'numdocumento'},
        {text:'Doc Responsable ',value:'RES_NumeroDocumento'},
        {text:'Responsable',value:'responsable'},
        {text:'Importe',value:'importe'},
        {text:'Fecha',value:'CON_Fecha'},
        {text:'Accion',value:'Accion'}
      ]
    }
  },
  methods:{
    ...mapActions('contrato',['cargarContrato']),
    ...mapActions('nav',['CargarMenu'])
  },

  computed:{
    ...mapGetters('contrato',['Items'])    
  }


};
</script>




