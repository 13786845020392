<template>
    <div>
    <Nuevo :dialog="dialog" @dialog="dialog = $event" :Item="itemSelected"/>
        <v-simple-table dense >
            <thead style="background:#FFAB00" dark>
                <tr>
                <th class="text-left" style="color:white">
                    Descripcion
                </th>
                <th class="text-left" style="color:white">
                    Cant
                </th>
                <th class="text-left" style="color:white">
                    Importe
                </th>
                <th class="text-left" style="color:white">                  
                    Opcion
                </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="(item) in ItemsDetalleTabla"
                :key="item.PRO_Id"
                >
                <td>{{ item.descripcion }}</td>
                <td>{{ item.CON_Cantidad }}</td>
                <td>{{ item.CON_Precio }}</td>
                <td>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                height="22"
                                width="22"
                                color="blue lighten-2"
                                fab
                                x-small
                                dark
                                @click="editarItem(item)"
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon x-small>mdi-pencil</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Editar</span> 
                    </v-tooltip>

                    
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                style="margin-left:5px"
                                height="22"
                                width="22"
                                color="red lighten-2"
                                fab
                                x-small
                                dark
                                @click="eliminar(item,index)"
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon x-small>mdi-delete</v-icon>
                            </v-btn>  
                        </template>
                        <span>Eliminar</span> 
                    </v-tooltip>

                </td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from "vuex";
    import Nuevo from './nuevo.vue'
    export default {
        data () {
            return {
                dialog: false,
                itemSelected: { }
            }
        },
        components:{
            Nuevo
        },
        methods:{
            editarItem(item) {
                this.itemSelected = item
                this.dialog = true;
            },
            eliminar(item) {
                this.$alertify.confirm(
                    "Desea Eliminar este Item del contrato " + item.descripcion + "?", 
                    () => {
                            this.eliminarDetalle(item)
                            .then(r => {
                                this.$alertify.success("Item ID: " + item.descripcion + " se a Eliminado correctamente");
                            });
                        }, 
                    () => this.$alertify.error("Se cancelo la acción")
                ).setHeader('<em> Aviso </em> ');
            },
            ...mapActions('contrato',['eliminarDetalle'])
        },
        computed:{
            ...mapGetters('contrato',['ItemsDetalleTabla'])    
        },
    }
</script>