<template>
    <v-parallax
      dark    
      height="2500"
      src="/imagen_login3.jpg"
    > 
    </v-parallax>
</template>
<script>

export default {
  name: "Home",
  
};
</script>
