<template>
    <div>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="Mini"
      disable-resize-watcher
      app
    >
      <v-list-item class="px-2">
        <v-list-item-avatar @click="navigation()" >
          <v-img src="https://apiabigail.buqkly.com/favicon.ico"></v-img>
        </v-list-item-avatar>

        <v-list-item-title>{{name}}</v-list-item-title>

        <v-btn
          icon
          @click="navigation()"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>    
      <v-list dense><v-list-group
          prepend-icon="mdi-dialpad"
          v-model="Menu0"
          color="success"
        >
          <template v-slot:activator>
            <v-list-item-title>Contrato</v-list-item-title>
          </template>
          
          <v-list-item-group            
            v-model="SubMenu0" >
            <v-list-item
              v-for="(item, i) in array0"
              :key="'submenu'+i"
              @click="insertarRuta(item.ruta,item.path)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>          
          </v-list-item-group>
        </v-list-group>
        <v-list-item-group
          color="success"
          v-model="Menu1"
        >
          <v-list-item
            prepend-icon="mdi-dialpad"
            v-for="item in array1"
            :key="item.title"
            mandatory
            color="success"
            @click="insertarRuta(item.ruta,item.path)"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-group
          prepend-icon="mdi-widgets"
          v-model="Menu2"
          color="success"
        >
          <template v-slot:activator>
            <v-list-item-title>Administración</v-list-item-title>
          </template>
          
          <v-list-item-group            
            v-model="SubMenu2" >
            <v-list-item
              v-for="(item, i) in array2"
              :key="'submenu'+i"
              @click="insertarRuta(item.ruta,item.path)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>          
          </v-list-item-group>
        </v-list-group>
        
        <v-list-group
          prepend-icon="mdi-domain"
          v-model="Menu3"
          mandatory
          color="success"
        >
          <template v-slot:activator>
            <v-list-item-title>Reportes</v-list-item-title>
          </template>
          
          <v-list-item-group            
            v-model="SubMenu3" >
            <v-list-item
              v-for="(item, i) in array3"
              :key="'submenu'+i"
              @click="insertarRuta(item.ruta,item.path)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>          
          </v-list-item-group>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>    
    <Bar :drawer= "drawer" @drawer="drawer = $event" />
    </div>
</template>

<script>

import Bar from '@/components/layout/Bar.vue';
import { mapGetters, mapActions } from "vuex";
  export default {
    data () {
      return {
        name: localStorage.getItem('name'),
        drawer: null,        
        array0 : [{
                    icon: 'mdi-circle',
                    text: 'Contrato',
                    ruta:'contrato',
                    path:'/contrato'
                  },
                  {
                    icon: 'mdi-circle',
                    text: 'Lista Contrato',
                    ruta:'listacontrato',
                    path:'/listacontrato'
                  }],
        array1: [{ 
                  icon: 'mdi-account-group-outline',
                  text: 'Responsable',
                  ruta:'responsable',
                  path:'/responsable'
                }],
        array2 : [{
                    icon: 'mdi-circle',
                    text: 'Tipo Vestidos',
                    ruta:'tipoproducto',
                    path:'/tipoproducto'
                  },
                  {
                    icon: 'mdi-circle',
                    text: 'Tipo Contrato',
                    ruta:'tipocontrato',
                    path:'/tipocontrato'
                  },
                  {
                    icon: 'mdi-circle',
                    text: 'Catalogo',
                    ruta:'producto',
                    path:'/producto'
                  },
                  {
                    icon: 'mdi-circle',
                    text: 'Cliente',
                    ruta:'cliente',
                    path:'/cliente'
                  }],
        array3 : [],
      }
    }, 
    methods:{
      ...mapActions('nav',['Minimizar']),
      insertarRuta(ruta,path){
        this.navigation();
        this.$router.replace({ path: path })
      },
      navigation(){
        this.Minimizar(!this.Mini);
      }
    },  
    computed:{
      ...mapGetters('nav',['Mini','Menu0','SubMenu0','Menu1','Menu2','SubMenu2','Menu3','SubMenu3']), 
    },    
    components:{
      Bar
    }
    
  }
</script>