<template>
  <v-app v-if="!isRegister"   >
    <v-app-bar app color="transparent" >
      <div class="d-flex align-center">
        <!-- <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="/logo.png"
          transition="scale-transition"
          width="60"
        /> -->
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="/logo.jpeg"
          transition="scale-transition"
          width="60"
        />

      </div>

      <v-spacer></v-spacer>
        <v-btn 
          v-for="(item, index) in rutas" :key="index"
          text @click="insertarRuta(item.ruta)">
          <span class="mr-2">{{item.nombre}}</span>
        </v-btn>

    </v-app-bar>

    <v-main >
      <router-view />
    </v-main>
  </v-app>

  <v-app 
  v-else  >
    <NavigationDrawer  />

    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid class="pa-0 ma-0">
        <!-- If using vue-router -->
        <router-view ></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>

import NavigationDrawer from '@/components/layout/NavigationDrawer.vue';
import { mapGetters, mapActions } from "vuex";
export default {
  name: "App",
  created(){
    this.isRegister = localStorage.getItem('logueo')
  },
  data: () => ({
    isRegister : '',
    rutas:[
      {nombre:'Login',ruta:'/login'},
    ]
  }),
  methods: {
    insertarRuta(ruta){
      this.$router.push(ruta);
    } 
  },
  components:{
    NavigationDrawer
  }
};
</script>


