<template>
<!-- <v-app-bar
      absolute
      color="#fcb69f"
      dark
      shrink-on-scroll
      src="//imagen_login2.jpg"
      scroll-target="#scrolling-techniques-2"
    >
    
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
        ></v-img>
      </template>

      

      <v-app-bar-title>Responsable</v-app-bar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar> -->
    <v-app-bar
    dense
    dark
    app
    color="secondary"
    elevate-on-scroll    
    hide-on-scroll
    scroll-target="#scrolling-techniques-7"
    >
    <v-app-bar-nav-icon
    @click="navigation()"
    ></v-app-bar-nav-icon>
    <v-spacer></v-spacer>

    <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
    </v-btn>

    <v-btn icon>
        <v-icon>mdi-heart</v-icon>
    </v-btn>
    <v-menu
        bottom
        left
        >
        <template v-slot:activator="{ on, attrs }">
            <v-btn            
            icon
            v-bind="attrs"
            v-on="on"
            >
            <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>

        <v-list dense>
        <v-subheader>OPCIONES</v-subheader>
        <v-list-item-group
            v-model="selectedItem"
            color="primary"
        >
            <v-list-item
            v-for="(item, i) in items"
            :key="i"
            >
            <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="Logout()">
                <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
        </v-list>
        </v-menu>
    </v-app-bar>
</template>
<script>
import { mapGetters, mapActions } from "vuex"; 
export default {
    props:['drawer'],
    data: () => ({
        selectedItem: 1,
        items: [
        { text: 'Cerrar Session', icon: 'mdi-account', funcion: 'Logout' },
        ],
    }),
    methods:{
        ...mapActions('nav',['Minimizar']),
        navigation(){
            if(!this.Mini && !this.drawer){
                this.$emit('drawer',!this.drawer);
            }else{
                this.Minimizar(!this.Mini);

            }
        },
        Logout() {            
            //localStorage.setItem('logueo','')
            localStorage.clear();
            sessionStorage.clear();
            window.location.reload();
            //router.push('/login')
        }
    },
    computed:{
        ...mapGetters('nav',['Mini'])    
    }
}
</script>
