<template>
    <div>
        <v-row justify="center">
            <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
            >
            <v-card>
                <v-card-title>
                <span v-if="Item.id == 0" class="text-h5">Nuevo Tipo de Contrato</span>
                <span v-else class="text-h5">Editar Tipo de Contrato</span>
                <v-spacer></v-spacer>
                <span v-if="Item.id > 0" >ID: {{Item.id}}</span>
                </v-card-title>
                <v-card-text>
                <v-container>
                    <!-- <FormularioDinamico :campos="campos"/> -->
                    <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                    >
                        <v-text-field
                        label="Nombre *"
                        hide-details="auto"
                        v-model="Item.TIC_Nombre"
                        required
                        ></v-text-field>
                    </v-col>
                    </v-row>
                </v-container>
                <small>*indica los campos requeridos</small>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="error"
                    @click="cerrarDialogo()"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    color="info"
                    @click="agregar()"
                >
                    Guardar
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    props:['dialog','Item'],
    methods:{
        ...mapActions('tipocontrato',['agregarTipoContrato',"cargarTipoContrato"]),
        agregar(){
            this.$alertify.confirm(
                this.Item.id  == 0 ? "Desea Agregar Item" : "Desea Editar Item", 
                () => {
                    this.agregarTipoContrato(this.Item);
                    this.cerrarDialogo();
                    this.$alertify.success(this.Item.id  == 0 ? "Agregado" : "Actualizado");
                    }, 
                () => this.$alertify.error("Se cancelo la acción")
            ).setHeader('<em> Aviso </em> ');
        },
        cerrarDialogo(){
            this.cargarTipoContrato;  
            this.$emit('dialog',false);
        }
    },
    data() {
        return {

        };
    },
}
</script>
